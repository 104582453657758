import { animated } from '@react-spring/web'
import classNames from 'classnames'
import type { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'

import type { WithTestId } from '../../lib/utils/testid'
import styles from './portal.module.scss'

/** An empty container for modals. This is not a dialog. It doesn't have any styles.
 * Its only job is to make sure it's rendered on top of everything else,
 * while fixing some fixed positioning bugs, mostly iOS.
 *
 * Speaking of iOS, make sure to test aaaany kind of component with a portal on an actual iPhone.
 * Especially if it prompts keyboard input.
 */
export const Portal = animated(
    ({
        children,
        'data-testid': testId,
        portalClassName,
        isOpen,
        ...restOfProps
    }: PropsWithChildren<Props>) => {
        return (
            <ReactModal
                isOpen={isOpen}
                portalClassName={classNames(styles.portal, portalClassName)}
                overlayClassName={styles.body}
                closeTimeoutMS={200}
                testId={testId}
                {...restOfProps}
            >
                {children}
            </ReactModal>
        )
    }
)

if (typeof window !== 'undefined') {
    const root = document.getElementById('__next')
    ReactModal.setAppElement(root || 'body')
}

type Props = WithTestId<PropsWithChildren<ReactModal.Props>>
