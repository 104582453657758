import { animated } from '@react-spring/web'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import type { FunctionComponent } from 'react'

import { BREAKPOINTS, maxWidth, useMedia } from '../../lib/hooks/useMedia'
import { snackbarStore } from '../../lib/store/ui/snackbar'
import type { Axis } from '../../types/styles'
import { Box } from '../box'
import { Portal } from '../portal'
import { useSnackbarBounce } from './hooks'
import styles from './snackbar.module.scss'

const AnimatedBox = animated(Box)

/**
 * Displays some important info for the user.
 */
export const Snackbar: FunctionComponent<React.PropsWithChildren<Props>> = ({
    content,
    visible,
    onHide,
    animationAxis = 'vertical',
    className,
}) => {
    const { box, portal } = useSnackbarBounce(animationAxis, visible)

    return (
        <Portal
            shouldCloseOnEsc
            isOpen={!!visible}
            onRequestClose={onHide}
            overlayElement={(props, children) => (
                <animated.div {...props} style={portal}>
                    {children}
                </animated.div>
            )}
            contentElement={(_, children) => (
                <div
                    aria-label={content}
                    tabIndex={0}
                    data-testid='snackbar.content'
                    role='status'
                >
                    {children}
                </div>
            )}
        >
            <AnimatedBox
                rounded
                shadow
                pad={[]}
                style={box}
                className={classNames(styles.snackbar, className)}
                background='step-white'
            >
                {content}
            </AnimatedBox>
        </Portal>
    )
}

interface Props {
    /** If true, the snackbar will be...visible! 😱 */
    visible?: boolean
    /** Text content inside the snackbar. */
    content: string
    /** If true, the snackbar will show and hide from/to the top. If false, then from/to the right. */
    animationAxis?: Axis
    /** This will fire if the snackbar is clicked or enter is pressed on focus. */
    onHide?: () => void
    /** Additional class names to apply to the snackbar. */
    className?: string
}

/**
 * MobX wrapper for the component that toggles it and sets content.
 * This should only be rendered once in the app.
 */
export const ObservedSnackbar: FunctionComponent<{ className?: string }> =
    observer(({ className }) => {
        const { content, visible, hide } = snackbarStore

        const verticalAnimation = useMedia(
            [maxWidth(BREAKPOINTS['medium'])],
            [true],
            true
        )

        return (
            <Snackbar
                className={className}
                visible={visible}
                content={content}
                onHide={hide}
                animationAxis={verticalAnimation ? 'vertical' : 'horizontal'}
            />
        )
    })

export default ObservedSnackbar
