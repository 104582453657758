import classNames from 'classnames'
import type { CSSProperties, HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { WithTestId } from '../../lib/utils/testid'
import type { BrandColor } from '../../types/color'
import type { ImageType } from '../../types/image'
import type { Axis, Position, Side } from '../../types/styles'
import sectionStyles from '../layout/section/section.module.scss'
import styles from './box.module.scss'

/** A simple responsively padded container with a background.
 * Accepts all the usual `div` props, except className. */
export const Box = forwardRef<HTMLDivElement, Props>(
    (
        {
            children,
            position,
            background,
            grow,
            shadow,
            rounded,
            justify,
            align,
            direction,
            shrink,
            className,
            pad = ['horizontal', 'vertical'],
            ...restOfProps
        },
        ref
    ) => {
        const attrs = getStyles({ background, ...restOfProps })

        return (
            <div
                ref={ref}
                style={attrs.style}
                className={classNames(
                    styles.box,
                    sectionStyles.box,
                    align && styles[`align-${align}`],
                    justify && styles[`justify-${justify}`],
                    direction && styles[direction],
                    position && styles[position],
                    className,
                    attrs.className,
                    ...pad.map((p) => styles[`pad-${p}`]),
                    {
                        [styles.grow]: grow,
                        [styles.shrink]: shrink,
                        [styles.rounded]: rounded,
                        [styles.shadow]: shadow,
                    }
                )}
                {...restOfProps}
            >
                {children}
            </div>
        )
    }
)

const getStyles = ({ background, style }: Partial<Props>) => {
    if (!background) return {}

    if (typeof background === 'string') {
        return { className: styles[`background-${background}`] }
    }

    return {
        className: styles['with-background-image'],
        style: { ...style, backgroundImage: imageWithFallback(background) },
    }
}

const imageWithFallback = (image?: ImageType) => {
    if (!image) return
    return `
        var(--step-linear-gradient-overlay),
        url(${image.source})
    `
}

interface Props
    extends Omit<HTMLAttributes<HTMLDivElement>, 'className'>,
        WithTestId {
    /** Background color or image. */
    background?: BrandColor | ImageType
    /** Selectively enable paddings for specific sides. Default is everywhere. */
    pad?: (Side | Axis)[]
    /** CSS position prop. */
    position?: Position
    /** Flex-grow. False by default. */
    grow?: boolean
    /** Flex-shrink. False by default. */
    shrink?: boolean
    /** Extra classname for extra special cases. */
    className?: string
    /** Add drop-shadow. */
    shadow?: boolean
    /** Add rounded corners. */
    rounded?: boolean
    /** Flex-direction. Unset by default. */
    direction?: CSSProperties['flexDirection']
    /** Justify items along the cross axis. Unset by default. */
    justify?: CSSProperties['justifyContent']
    /** Justify items along the main axis. Unset by default. */
    align?: CSSProperties['alignItems']
}
